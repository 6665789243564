import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
	const [location, setLocation] = useState<GeolocationPosition>();
	const [skiAreaName, setSkiAreaName] = useState<string>('');
	const [skiAreaID, setSkiAreaID] = useState<string>('');

	useEffect(() => {
		const success = (position: GeolocationPosition) => {
			setLocation(position);
		};

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(success, (e) => {
				console.log(e);
			});
		} else {
			console.log('Geolocation not supported');
		}
	}, []);

	useEffect(() => {
		const fetchSkiArea = async () => {
			if (location === undefined) {
				return;
			}

			const params = new URLSearchParams({
				lat: location?.coords.latitude.toString(),
				lng: location?.coords.longitude.toString(),
			}).toString();

			const url = '/api/v1/closestskiarea?' + params;

			const resortData = await axios.get(url, {});

			setSkiAreaName(resortData.data.name);
			setSkiAreaID(resortData.data.objectid);
		};

		fetchSkiArea().catch(console.error);
	}, [location, skiAreaName, skiAreaID, setSkiAreaName, setSkiAreaID]);

	if (
		skiAreaID === '' ||
		skiAreaID === undefined ||
		skiAreaName === '' ||
		skiAreaName === undefined
	) {
		return (
			<div className="flex flex-col w-full h-screen bg-slate-300 font-sans">
				<div className="flex grow w-full justify-center">
					<div className="flex grow h-full justify-center items-center">
						<p className="text-4xl font-sans">Unable to get location</p>
					</div>
				</div>
				<Footer />
			</div>
		);
	}

	return (
		<div className="flex flex-col w-full h-screen bg-slate-300 font-sans">
			<div className="flex grow w-full justify-center">
				<div className="flex grow h-full justify-center items-center">
					<a
						className="text-4xl font-sans underline text-blue-600 hover:text-blue-800"
						href={`https://openskimap.org/?obj=${skiAreaID}`}
					>
						{skiAreaName}
					</a>
				</div>
			</div>
			<Footer />
		</div>
	);
}

const Footer = () => {
	return (
		<div className="flex grow-0">
			<div className="flex flex-wrap border-t border-t-black items-center justify-center h-[55px] gap-2 w-full text-xs">
				<p>
					Ski Area data provided by <a href="https://openskimap.org">OpenSkiMap.org</a> and{' '}
					<a href="https://openstreetmap.org">OpenStreetMap</a>
				</p>
				<p>
					Icons by <a href="https://icons8.com">Icons8</a>{' '}
				</p>
				<p>Made with love in CO</p>
			</div>
		</div>
	);
};
export default App;
